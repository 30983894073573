import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { LaneDetailViewComponent } from './pages/inventory-view/lane-detail-view/lane-detail-view.component';

export enum AppRoutes {
  Main = '',
  Dashboard = 'dashboard',
  Logs = 'logs',
  NotFound = '**',
  detailPage = 'progress/:type/:id',
  progress = 'progress',
  progressType = 'progress/:type',
  divertedLaneMonitor = 'divert-lane',
  divertedLaneMonitorDetailPage = 'divert-lane/:id',
  historyView = 'history-view',
  historyOverallView = 'history-view/overall'
}

export const routes: Routes = [
  {
    path: AppRoutes.Main,
    redirectTo: AppRoutes.Dashboard,
    pathMatch: 'full',
  },
  {
    path: AppRoutes.Dashboard,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    loadComponent: () =>
      import('./pages/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
    children: [
      {
        path: AppRoutes.Main,
        redirectTo: AppRoutes.progress,
        pathMatch: 'full',
      },
      {
        path: AppRoutes.progress,
        title: AppRoutes.progress,
        loadComponent: () => 
          import('./pages/progress-view/progress-view.component').then(
            (c) => c.ProgressViewComponent
          )
      },
      {
        path: AppRoutes.progressType,
        loadComponent: () => 
          import('./pages/progress-view/progress-view.component').then(
            (c) => c.ProgressViewComponent
          )
      },
      {
        path: AppRoutes.divertedLaneMonitor,
        loadComponent: () => 
          import('./pages/inventory-view/inventory-view.component').then(
            (c) => c.InventoryViewComponent
          )
      },
      {
        path: AppRoutes.divertedLaneMonitorDetailPage,
        loadComponent: () => 
          import('./pages/inventory-view/lane-detail-view/lane-detail-view.component').then(
            (c) => c.LaneDetailViewComponent
          )
      },
      {
        path: AppRoutes.detailPage,
        loadComponent: () => 
          import('./pages/progress-view/detail-progress-view/detail-progress-view.component').then(
            (c) => c.DetailProgressViewComponent
          )
      },
      {
        path: AppRoutes.historyView,
        loadComponent: () => 
          import('./pages/history-view/history-view.component').then(
            (c) => c.HistoryViewComponent
          ),
      },
      {
        path: AppRoutes.historyOverallView,
        pathMatch: 'full',
        loadComponent: () => import('./pages/history-view/components/cornerstone-recirculation/view-cornerstone-recirculation/view-cornerstone-recirculation.component')
          .then(c => c.ViewCornerstoneRecirculationComponent)
      },
    ]
  },
  {
    path: AppRoutes.Logs,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./pages/logs/logs.component').then(
        (c) => c.LogsComponent
      ),
  },
  {
    path: AppRoutes.NotFound,
    loadComponent: () =>
      import('./pages/page-not-found-component/page-not-found-component.component').then(
        (c) => c.PageNotFoundComponentComponent
      ),
  },
];