import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken = 'YOUR_AUTH_TOKEN_HERE';

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer`
    }
  });

  console.log('Request with Authorization Token:', authReq);

  return next(authReq).pipe(
    catchError((err: any) => { 
        console.log(err.status, 'err.status')
        if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          console.error('Unauthorized request:', err);
        } else {
          console.error('HTTP error:', err);
        }
      } else {
        console.error('An error occurred:', err);
      }

      return throwError(() => err); 
    })
  );;
};