import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DataService } from './shared/services/data/data.service';
import { HeaderComponent } from './layout/header/header.component';
import { ApiService } from './shared/services/api/api.service';
import { ToastrModule } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    HttpClientModule,
    HeaderComponent,
    ToastrModule,
  ],
  providers: [DataService, ApiService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public loadData: boolean = false;

  constructor(public dataService: DataService){}

  ngOnInit(): void {
  }
}
