import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { DataService } from './shared/services/data/data.service';
import { HeaderComponent } from './layout/header/header.component';
import { ApiService } from './shared/services/api/api.service';
import { ToastrModule } from 'ngx-toastr';
import { ErrorInterceptor } from './shared/interceptor/error-interceptor';

//Authentication Services
import { AuthenticationService } from './shared/services/auth/authentication.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    HttpClientModule,
    HeaderComponent,
    ToastrModule,
  ],
  providers: [DataService, ApiService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(private authenticationService: AuthenticationService){}

  ngOnInit(): void {
  }
}
